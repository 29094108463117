/* global settings */
$color-variant: 'red';

/* functional includes */
@import "mixins/fab-unit";
@import "mixins/fab-slider";
@import "mixins/fab-fluid";

/* basic framework and variables */
@import "variables";
@import "bootstrap";
@import "fonts";

/* base layout */
@import "typo";
@import "layout";
@import "mainnav";
@import "sidebarmenu";

/*
 some little changes
 */

html, body {
  background-color: #fff;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.navbar .brand {
  filter: blur(5px);
}

.error-content {
  width: 100%;
  height: calc(100vh - 80px - 10rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  margin-bottom: 20px;
}
.code,
.message {
  font-family: $--font-stack-deko;
  font-size: $fab-font-size-h3;
}
